import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpeg`} alt="" />
      </Link>
      <header>
        <h2>Cristian Pandele</h2>
        {/* <p><a href="mailto:random@yahoo.com">random@yahoo.com</a></p> */}
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Cristian. I like experimenting and building things with software.
        I am looking for a challenging position that focuses on technologies within OOP projects.
        I am also currently studying danish at Trin 3 level.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      {/* <p className="copyright">&copy; Mic D&apos;An <Link to="/">mldangelo.com</Link>.</p> */}
    </section>
  </section>
);

export default SideBar;
